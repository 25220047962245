import { render, staticRenderFns } from "./CityIndex.vue?vue&type=template&id=218510c4&"
import script from "./CityIndex.vue?vue&type=script&lang=js&"
export * from "./CityIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports